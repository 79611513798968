<template>
  <div class="form_ct_box">
    <div class="flex cofirm_box">
      <button class="cancel" @click="$router.push('/placard_user/pub_placard/pub_outdoors')">
        取消
      </button>

      <button v-if="!$route.query.id" class="affirm" @click="addMedia">
        确认
      </button>
      <button v-else class="affirm" @click="updateFn">修改</button>
    </div>
    <div class="flex ct_box">
      <div class="ct_left_box">
        <div class="flex" style="margin-bottom: 20px; align-items: center;">
          <add_select :add_value="form.portal_id" title="广告类型" class_name="portal_id" :list="portal_list"
                      @get_val="get_val"/>
          <!--          <add_platform class_name="广告平台" category_id="1" />-->
        </div>
        <div class="flex" style="align-items: center;margin-bottom: 20px;">
          <p class="title_p">户外名称：</p>
          <el-input v-model="form.title" placeholder="请输入平台名称"></el-input>
        </div>
        <div class="flex" style="align-items: center;margin-bottom: 20px;">
          <p class="title_p">广告位置：</p>
          <el-input v-model="form.address" placeholder="输入广告所在位置"></el-input>
        </div>
        <div class="flex" style="margin-bottom: 20px;">
          <p class="title_p">广告说明：</p>
          <vue-ueditor-wrap id="tinymce" v-model="form.content" :config="my_config"></vue-ueditor-wrap>
        </div>
        <add_delivery title="投放价" :form_data="form.guanggao_price" @change_value="change_value"/>
      </div>
      <div class="ct_right_box">
        <add_select title="覆盖区域" :add_value="form.area_id" class_name="area_id" :list="area_list"
                    @get_val="get_val"/>
        <specialText text="规格设计"/>
        <add_select title="设计单位" :add_value="form.design_unit" class_name="design_unit"
                    :design_rule="form.design_rule"
                    @get_val="get_val" @design_rule_change="design_rule_change"/>
        <div class="flex" style="align-items: center;margin-bottom: 20px;">
          <p class="title_p">设计价格：</p>
          <el-input v-model="form.design_price" placeholder="请输入设计价格"></el-input>
        </div>
        <div class="flex" style="align-items: center;margin-bottom: 20px;">
          <p class="title_p">备注：</p>
          <el-input type="textarea" resize="none" :rows="2" v-model="form.remark" placeholder="请输入备注"></el-input>
        </div>
        <upLoadImg title="投放案例" :limit="3" :case_url="form.case_url" @get_img="get_img"/>
        <placard_add_upload/>
      </div>
    </div>
  </div>
</template>

<script>
import add_select from '@/components/add_select'
import add_platform from '@/components/add_platform'
import add_delivery from '@/components/add_delivery'
import VueUeditorWrap from 'vue-ueditor-wrap'
import config from '@/util/ueditor.config'
import placard_add_upload from '@/components/placard_add_upload'

export default {
  components: {
    add_select,
    add_platform,
    add_delivery,
    VueUeditorWrap,
    placard_add_upload
  },
  mounted() {
    console.log('执行1')
    this.get_data()
    if (this.$route.query.id) {
      this.get_update_data()
    }
  },
  data() {
    return {
      area_list: [],
      portal_list: [],
      form: {
        category_id: '1',
        portal_id: '',
        title: '',
        address: '',
        content: '',
        guanggao_price: [],
        area_id: '',
        design_unit: '',
        design_rule: '',
        design_price: '',
        remark: '',
        case_url: '',
      },
      my_config: config,
    }
  },
  methods: {
    get_update_data() {
      let data = {
        id: this.$route.query.id
      }
      this.curlGet('/api/users/guanggao_info/detail', data).then(res => {
        if (res.data.code) {
          for (const key in res.data.data) {
            this.form[key] = res.data.data[key]
          }
        }
      })
    },
    get_data() {
      this.curlGet('/api/guanggao_info/init').then(res => {
        if (res.data.code) {
          let data = res.data.data[1];
          this.area_list = data.area_list
          this.portal_list = data.portal_list
        }
      })
    },
    // 添加媒体
    addMedia() {
      this.$confirm('确认提交吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let data = {}
        this.form.guanggao_price = JSON.stringify(this.form.guanggao_price)
        for (const key in this.form) {
          data[key] = this.form[key]
        }
        this.curlPost('/api/users/guanggao_info/save', data).then((res) => {
          console.log(res)
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: res.data.msg,
            })
            return this.$router.push('/placard_user/pub_placard/pub_outdoors')
          } else {
            return this.$message({
              type: 'warning',
              message: res.data.msg,
            })
          }
        })
      })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
    },
    // 修改媒体
    updateFn() {
      console.log(this.form.case_url);
      this.$confirm('确认修改吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let data = {}
        data.id = this.$route.query.id
        this.form.guanggao_price = JSON.stringify(this.form.guanggao_price)
        for (const key in this.form) {
          data[key] = this.form[key]
        }
        this.curlPost('/api/users/guanggao_info/save', data).then((res) => {
          if (res.data.code) {
            this.$message({
              type: 'success',
              message: '已修改',
            })
            return this.$router.push('/placard_user/pub_placard/pub_outdoors')
          } else {
            return this.$message({
              type: 'warning',
              message: res.data.msg,
            })
          }
        })
      })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
    },
    get_val(type, val) {
      this.form[type] = val
    },
    change_value(val) {
      this.form.guanggao_price = val
    },
    design_rule_change(val) {
      this.form.design_rule = val
    },
    get_img(url) {
      this.form.case_url += url + ','
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/AddResource';
</style>